import React, { useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { listProducts } from '../actions/productActions';
import Product from '../components/Product';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { listTopSellers } from '../actions/userActions';

export default function HomeScreen() {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;
   
  useEffect(() => {
    dispatch(listProducts({}));
    dispatch(listTopSellers());
     }, [dispatch]);
  
  return (
    <div>

      <h1>פרטים מקוריים לעיצוב פנים</h1>
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <>
        {products.length === 0 && <MessageBox>לא נמצא מוצר</MessageBox>}
        <div className="row center">
          {products.map((product) => (
            <Product key={product._id} product={product}></Product>
          ))}
        </div>
      </>
      )}
    </div>
  );
}