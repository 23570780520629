import Axios from 'axios';
import { PayPalButton } from 'react-paypal-button-v2';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deliverOrder, detailsOrder, payOrder } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import {
  ORDER_DELIVER_RESET,
  ORDER_PAY_RESET,
} from '../constants/orderConstants';

export default function OrderScreen(props) {
  const orderId = props.match.params.id;
  const [sdkReady, setSdkReady] = useState(false);
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  
  const orderPay = useSelector((state) => state.orderPay);
  const {
    loading: loadingPay,
    error: errorPay,
    success: successPay,
  } = orderPay;
  const orderDeliver = useSelector((state) => state.orderDeliver);
  const {
    loading: loadingDeliver,
    error: errorDeliver,
    success: successDeliver,
  } = orderDeliver;
  const dispatch = useDispatch();
  useEffect(() => {
    const addPayPalScript = async () => {
      const { data } = await Axios.get('/api/config/paypal');
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://www.paypal.com/sdk/js?client-id=${data}`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      document.body.appendChild(script);
    };
    if (
      !order ||
      successPay ||
      successDeliver ||
      (order && order._id !== orderId)
    ) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch(detailsOrder(orderId));
    } else {
      if (!order.isPaid) {
        if (!window.paypal) {
          addPayPalScript();
        } else {
          setSdkReady(true);
        }
      }
    }
  }, [dispatch, orderId, sdkReady, successPay, successDeliver, order]);

  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(order, paymentResult));
  };
  const deliverHandler = () => {
    dispatch(deliverOrder(order._id));
  };

  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div>
      <h1>להזמין {order._id}</h1>
      <div className="row top">
	  <div className="col-1">
          <div className="card card-body">
            <ul>
              <li>
                <h2>סיכום הזמנה</h2>
              </li>
              <li>
                <div className="row">
                   <div>₪ {order.itemsPrice.toFixed(2)}</div>
                  <div>פריטים</div>
                </div>
              </li>
              <li>
                <div className="row">
                   <div>₪ {order.shippingPrice.toFixed(2)}</div>
                  <div>משלוח</div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div>₪ {order.taxPrice.toFixed(2)}</div>
                  <div>מַס</div>
                </div>
              </li>
              <li>
                <div className="row">
                   <div>
                    <strong>₪ {order.totalPrice.toFixed(2)}</strong>
                  </div>
                  <div>
                    <strong> סך כל ההזמנה</strong>
                  </div>
                </div>
              </li>
              {!order.isPaid && (
                <li>
                  {!sdkReady ? (
                    <LoadingBox></LoadingBox>
                  ) : (
                    <>
                      {errorPay && (
                        <MessageBox variant="danger">{errorPay}</MessageBox>
                      )}
                      {loadingPay && <LoadingBox></LoadingBox>}

                      <PayPalButton
                        amount={order.totalPrice}
                        onSuccess={successPaymentHandler}
                      ></PayPalButton>
                    </>
                  )}
                </li>
              )}
                            {userInfo.isAdmin && order.isPaid && !order.isDelivered && (
                <li>
                  {loadingDeliver && <LoadingBox></LoadingBox>}
                  {errorDeliver && (
                    <MessageBox variant="danger">{errorDeliver}</MessageBox>
                  )}
                  <button
                    type="button"
                    className="primary block"
                    onClick={deliverHandler}
                  >
                    לספק הזמנה
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
	  
        <div className="col-2">
          <ul>
            <li>
              <div className="card card-body">
              <h2>שיפרינג</h2>
                <p>
                  {order.shippingAddress.fullName} <strong>:שֵׁם</strong><br />
                  {order.shippingAddress.address},
                  {order.shippingAddress.city},{' '}
                  {order.shippingAddress.postalCode},
                  {order.shippingAddress.country} <strong>:כתובת</strong>
                </p>
                {order.isDelivered ? (
                  <MessageBox variant="success">
                    נמסר בשעה {order.deliveredAt}
                  </MessageBox>
                ) : (
                  <MessageBox variant="danger">לא נשלח</MessageBox>
                )}
              </div>
            </li>
            <li>
              <div className="card card-body">
                <h2>תַשְׁלוּם</h2>
                <p>
                  {order.paymentMethod} <strong>:שיטה</strong>
                </p>
                {order.isPaid ? (
                  <MessageBox variant="success">
                    שילם ב {order.paidAt}
                  </MessageBox>
                ) : (
                  <MessageBox variant="danger">לא משולם</MessageBox>
                )}
              </div>
            </li>
            <li>
              <div className="card card-body">
                <h2>פריטי הזמנה</h2>
                <ul>
                  {order.orderItems.map((item) => (
                    <li key={item.product}>
                      <div className="row">
                        <div>
                          {item.qty} x ₪ {item.price} = ₪ {item.qty * item.price}
                        </div>
                         <div className="min-30">
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </div>
                        <div>
                          <img
                            src={item.image}
                            alt={item.name}
                            className="small"
                          ></img>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          </ul>
        </div>
        
      </div>
    </div>
  );
}